<template>
	<div class="bg-F7F7F7">
		<div class="relative">
			<!-- <img class=" w100 display-block" :src="teacherInfo.pc_bg_image||teacherInfo.bg_image" alt="" /> -->
			<el-image class="" style="width:100%;height:600px;"  :src="teacherInfo.pc_bg_image||teacherInfo.bg_image"   fit="cover" lazy></el-image>
		</div>
		<div class="center flex-column">
			<div class="w1200 info bg-white relative">
				<div style="opacity: 0;">
					<div class="head-pics">
						<img class="img_02 display-block" src="@/assets/img/img_02.png" />
					</div>
					<div class="justify-content-center-between mart15 paddb20">
						<div class="align-items-center">
							<div class="flex-column center">
								<span class="fz16 co-333333 font-blod">{{teacherInfo.view}}</span>
								<span class="fz12 co-999999 mart2 display-block">浏览</span>
							</div>
							<div class="middle"></div>
							<div class="flex-column center">
								<span class="fz16 co-333333 font-blod">790</span>
								<span class="fz12 co-999999 mart2 display-block">预约人数</span>
							</div>
						</div>
					</div>
					<div class="align-items-center bodert1-E6E6E6 bg-white h40"></div>
				</div>
				<div class="absolute top0 left0 right0 bottom0 info-01">
					<div class="display-flex">
						<div class="head-pics marl20">
							<!-- <img class="img_02 display-block" :src="teacherInfo.avatar" /> -->
							<el-image class="img_02 display-block" :src="teacherInfo.avatar" lazy></el-image>
						</div>

						<div class="mart12 flex-1 justify-content-between marl12">
							<div>
								<span class="fz20 co-333333 font-blod one-point"
									style="width: 500px;">{{teacherInfo.name|| ''}}</span>
								<div class="follow-btn center mart10 cure-pointer" style="width: 70px;" @click="follow">
									<img class="folloe_icon display-block" src="@/assets/img/folloe_icon.png" alt="" />
									<span class="fz14 co-010085">{{isfollow ? '已关注' : '关注'}}</span>
								</div>
							</div>

							<div class="display-flex cure-pointer" @click="rightClick">
								<img class="de_04 display-block marr32 cure-pointer" src="@/assets/img/de_04.png"
									alt="" />
								<img class="de_04 display-block marr32 cure-pointer" src="@/assets/img/de_02.png"
									alt="" />
								<img class="de_04 display-block marr32 cure-pointer" src="@/assets/img/de_05.png"
									alt="" />
								<img class="de_04 display-block marr20 cure-pointer" src="@/assets/img/de_06.png"
									alt="" />
							</div>
						</div>
					</div>

					<div class="justify-content-center-between mart15 marl24 paddb20">
						<div class="align-items-center">
							<div class="flex-column center">
								<span class="fz16 co-333333 font-blod l16">{{teacherInfo.view}}</span>
								<span class="fz12 co-999999 mart2 display-block">浏览</span>
							</div>
							<div class="middle"></div>
							<div class="flex-column center">
								<span class="fz16 co-333333 font-blod l16">{{teacherInfo.sign_num}}</span>
								<span class="fz12 co-999999 mart2 display-block">预约人数</span>
							</div>
						</div>

						<div class="display-flex mart5">
							<div class="btn-01 center fz16 co-010085 cure-pointer" @click="free">免费预约试听课</div>
							<div class="btn-02 center fz16 co-010085 marl36 marr20 cure-pointer" @click="online">在线咨询
							</div>
						</div>
					</div>

					<div class="align-items-center bodert1-E6E6E6 bg-white h80 ">
						<div class=" display-flex"  style="width: 300px;align-items: center;">
							<img class="td_01  marl20 " src="@/assets/img/td_01.png" alt="" />
							<span class="fz14 co-333333 marl12">{{teacherInfo.school}}</span>
						</div>
						<div class=" display-flex" style="width: 300px;align-items: center;">
							<img class="td_01  marl12" src="@/assets/img/td_02.png" alt="" />
							<span class="fz14 co-333333 marl12">{{teacherInfo.guide}}</span>
						</div>
						<div class=" display-flex" style="width: 300px; align-items: center;" > 
							<img class="td_01  marl12" src="@/assets/img/td_03.png" alt="" />
							<span class="fz14 co-333333 marl12">{{teacherInfo.city}}</span>
						</div>
						<div class="   display-flex "   style="align-items: center;" >
							<img class="td_01  " src="@/assets/img/td_04.png" alt="" />
							<span class="fz14 co-333333 marl12 two-point">{{teacherInfo.desc}}</span>
						</div>
					</div>
				</div>
			</div>

			<div class="mart10 w1200 bg-white">
				<div class="list">
					<div class="justify-content-center-between h40 cure-pointer"
						@click="toServce(teacherInfo.services)">
						<span class="fz16 co-333333 font-blod">服务项目</span>
						<img class="arrow_right_blank display-block" src="@/assets/img/arrow_right_blank.png" />
					</div>
				</div>
				<Service :list="teacherInfo.services" :type='1'></Service>
			</div>

			<div class="mart10 h240"> 
				<div class="w1200 margin-center h240 justify-content-center-between swiper-indexs">
					<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="true" :pagination='pagination'
						:navigation="navigation" class="mySwiper" v-if="teacherInfo.interview.length>0">
						<swiper-slide class="slider-box  cure-pointer" v-for="(item,index) in teacherInfo.interview"
							:key='index' @click="godetail(item)">
							<el-image style='width: 100%;' :src="item.image" lazy></el-image>
							<div class="absolute left0 top0 right0 bottom0 center img-box flex-column">
								<div class="fz16 co-white font-blod">{{item.title}}</div>
								<div class="fz14 co-CCCCCC mart8 w240 text-center">{{item.mini_title}} </div>
							</div>
						</swiper-slide>
						<div class="swiper-button-prev"></div>
						<div class="swiper-button-next"></div>
					</swiper>

					<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="true" :pagination='pagination'
						:navigation="navigation" class="mySwiper" v-if="teacherInfo.teacher_works.length>0">
						<swiper-slide class="slider-box" v-for="(item,index) in teacherInfo.teacher_works" :key='index'  @click="preventMove(index,teacherInfo.teacher_works)">
							<el-image style='width: 100%;' :src="item" lazy></el-image>
							<div class="absolute left0 top0 right0 bottom0 center img-box flex-column">
								<div class="fz16 co-white font-blod">导师作品</div>
							</div>
						</swiper-slide>
						<div class="swiper-button-prev"></div>
						<div class="swiper-button-next"></div>

					</swiper>

					<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="true" :pagination='pagination'
						:navigation="navigation" class="mySwiper" v-if="teacherInfo.student_works.length>0">
						<swiper-slide class="slider-box" v-for="(item,index) in teacherInfo.student_works" :key='index' @click="preventMove(index,teacherInfo.student_works)">
							<el-image style='width: 100%;height: 100%;' :src="item" lazy></el-image>
							<div class="absolute left0 top0 right0 bottom0 center img-box flex-column">
								<div class="fz16 co-white font-blod">学生作品作品</div>
							</div>
						</swiper-slide>
						<div class="swiper-button-prev"></div>
						<div class="swiper-button-next"></div>
					</swiper>
				</div>
			</div>


			<div class="mart10 w1200 bg-white" v-if="teacherInfo.student_school.length>0">
				<div class="list">
					<div class="justify-content-center-between h40 cure-pointer"
						@click="toSchoolList(teacherInfo.student_school)">
						<span class="fz16 co-333333 font-blod">学生录取院校</span>
						<img class="arrow_right_blank display-block" src="@/assets/img/arrow_right_blank.png" />
					</div>
				</div>

				<div class="justify-content-center-between mart10">
					<div class="school-item center flex-column " v-for="(item, i) in teacherInfo.student_school"
						:key="i">
						<img class="school-pic display-block" :src="item.image" alt="" />
						<span
							class="mart12 fz14 co-666666 display-block mart12  text-center paddlr20">{{item.name}}</span>
					</div>
				</div>
			</div>
		</div>

		<el-dialog title="填写报名信息" v-model="centerDialogVisible" top="0" custom-class="model-box" width="550px" center>
			<div class="h54 paddl20 paddr19 align-items-center display-flex">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">名称</span>
				</div>
				<div class="input-area marl20">
					<input class="fz16 co-333333 popup-input h100 w100" v-model="listObj.name" placeholder="请输入名称" />
				</div>
			</div>
			<div class="h54 paddl20 paddr19 align-items-center">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">联系电话</span>
				</div>
				<div class="input-area marl20">
					<input class="fz16 co-333333 popup-input h100 w100" v-model="listObj.contact" type="text"
						maxlength="11" placeholder="请输入你的联系电话" />
				</div>
			</div>
			<div class="h54 paddl20 paddr19 align-items-center">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">微信号</span>
				</div>
				<div class="input-area marl20">
					<input class="fz16 co-333333 popup-input h100 w100" placeholder="请输入微信号"
						v-model="listObj.wechat_number" />
				</div>
			</div>
			<div class="h54 paddl20 paddr19 align-items-center">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">作品集</span>
				</div>
				<div class="input-area marl20">
					<input class="fz16 co-333333 popup-input h100 w100" placeholder="请输入作品集链接"
						v-model="listObj.works" />
				</div>
			</div>
			<div class=" paddl20 paddr19  display-flex">
				<div class="name " style="width:105px;display: inline-block;">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">作品图片</span>
				</div>
				<div @click="toGetImg" style="width: 400px;display: inline-block;">
					<el-upload class="avatar-uploader" action="https://yunansi.togy.com.cn/api/upload" :limit="9"
						multiple list-type='picture-card' :on-success="handleAvatarSuccess1"
						:before-upload="beforeAvatarUpload" :on-exceed="handleExceed"
						:on-preview="handlePictureCardPreview">
						<i class="el-icon-plus"></i>
					</el-upload>
				</div>
			</div>
			<div class="h54 paddl20 paddr19 align-items-center">
				<div class="name">
					<span class="fz16 co-FE3000">*</span>
					<span class="fz16 co-333333 marl8 name-text">预约时间</span>
				</div>
				<div class=" marl20">
					<!-- <input class="fz16 co-333333 popup-input h100 w100" placeholder="请选择预约时间"  disabled v-model="itme" /> -->
					<el-date-picker v-model="listObj.order_time" @change='datePickerChange' type="date"
						placeholder="请选择预约时间">
					</el-date-picker>
				</div>
			</div>
			<div class="paddb20 mart15">
				<div class="sure-btn fz14 co-010085 center cure-pointer center" @click="submit">提交</div>
			</div>
		</el-dialog>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
	<!-- 轮播图浴帘 -->
	<div id="mask" v-if="mask">
	    <div class="swiper">
	        <el-carousel trigger="click" height="617px" :interval='2000' arrow="always"  
	            :initial-index='clickindex'>
	            <el-carousel-item v-for="item in templateinfo" :key="item">
	                <el-image style="width: 100%;height: 100%;" fit="scale-down"  :src="item" >
	                </el-image>
	            </el-carousel-item>
				<div class="icon-position " @click="stopcick">
				     <i class="el-icon-close" style="font-size: 36px;color: #969696;"></i>
				 </div>
	        </el-carousel>
			
	    </div>
	   
	</div>
	<el-dialog v-model="dialogVisible" width='800px'>
		<div class="display-flex">
			<img style="width: 100%;" :src="dialogImageUrl" alt="">
		</div>
	</el-dialog>
	<tgshare ref="sharePopup" :dataObj="dataObj"
		:shareUrl="'www.archipocket.com/#/TeacherDetail?id='+id+'&type='+type"></tgshare>
</template>

<script>
	import {
		useRoute
	} from 'vue-router';
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import 'swiper/swiper.less';
	import {
		$on,
		$emit
	} from 'vue-happy-bus'
	import "swiper/components/pagination/pagination.min.css";
	import "swiper/components/navigation/navigation.min.css";

	import SwiperCore, {
		Autoplay,
		Pagination,
		Navigation
	} from 'swiper/core';
	SwiperCore.use([Autoplay, Pagination, Navigation]);

	export default {
		name: "TeacherDetail",
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				templateinfo:[],
				dialogVisible: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},

				pagination: {
					"clickable": true,
					"bulletActiveClass": "box-01-active",
					"type": 'bullets'
				}, // 分页
				centerDialogVisible: false, // 打开弹窗
				name: '', // 名称
				phone: '', // 联系电话
				wechat: '', // 微信号
				teacherInfo: {
					"id": 1,
					"avatar": "",
					"name": "",
					"profession": "",
					"school": "",
					"bg_image": "",
					"view": 0,
					"is_collect": 0,
					"collect": 0,
					"guide": "",
					"degree": "",
					"direction": "",
					"city": "",
					"desc": "",
					"services": [],
					"interview": [],
					"teacher_works": [],
					"student_works": [],
					"student_school": [],
					"is_reserve": 0
				}, //教师信息
				id: '', //跳转页面获取到的id
				type: '',
				obj: {}, //报名参数
				isfollow: false, // 是否关注
				dataObj: {},
				fullscreenLoading: true, //加载中
				listObj: {
					name: '',
					contact: '',
					wechat_number: '',
					works: '',
					works_image: [],
					order_time: ""


				},
				dialogImageUrl: '',
				mask: false,
				clickindex:0
			}
		},
		created() {
			let data = useRoute().query;
			this.type = data.type;
			this.id = data.id;

		},
		mounted() {
			this.getDetail();
			let data = useRoute().query;
			if (data.share == 1) {
				let obj = {
					id: data.id,
					type: data.type,
					uuid: localStorage.getItem('uuid'),
					share_uid: data.userId, //分享者uid
					share_time: data.shareTime
				}
				console.log(obj, '分享参数')
				this.$req({
					method: 'put',
					url: '/api/member/share',
					data: {
						...obj
					},
					success: res => {
						console.log(res)
					},
					fail: error => {
						console.log(error)
					}
				});
			}
		},
		methods: {
			stopcick() {
			    document.body.style.overflow = "visible";
			    this.mask = !this.mask;
			},
			preventMove(index,imglist) {
				this.templateinfo = [];
			    document.body.style.overflow = "hidden";
			    this.clickindex = index;
			    this.mask = !this.mask;
				this.templateinfo =imglist
				console.log(this.templateinfo )
			},
			rightClick() {
				this.$refs.sharePopup.open()
			},
			beforeAvatarUpload(file) {
				const isLt4M = file.size / 1024 / 1024 < 4;
				if (!isLt4M) {
					this.$message.error('上传图片大小不能超过 4MB!');
				}
				return isLt4M;

			},
			godetail(item) {
				console.log(item)
				this.$router.push({
					path: '/Detail',
					query: {
						id: item.id,
						type: 3,
					}
				})
			},
			selectDate(dateData) {
				let date = new Date(dateData)
				let y = date.getFullYear()
				let m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				let d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				const time = y + '-' + m + '-' + d;
				this.listObj.order_time = time;
			},
			datePickerChange(event) {
				this.selectDate(event)
			},
			handleAvatarSuccess1(res) {
				this.listObj.works_image.push(res.data)

			},
			handleExceed(files, fileList) {

				this.$message.warning(`当前限制选择 9 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			// 获取教师详情
			getDetail() {
				this.$req({
					method: 'get',
					url: '/api/teacher/detail',
					data: {
						id: this.id,
					},
					success: res => {
						console.log(res)
						this.fullscreenLoading = false;
						this.isfollow = res.is_collect
						this.teacherInfo = res
						this.dataObj = { //分享参数
							title: res.title,
							id: this.id,
						}
						this.type = res.type
						console.log(res, "教师详情")
					},
					fail: error => {
						this.fullscreenLoading = false;
					}
				});

			},
			handlePictureCardPreview(file) {

				this.dialogImageUrl = file.response.data;
				this.dialogVisible = true;
			},
			// 在线咨询
			online() {
				$emit('openadmin', '打开客服');

			},
			// 显示免费预约弹窗
			free() {
				this.centerDialogVisible = true
			},
			// 提交
			submit() {
				if (!this.listObj.name) {
					this.$notify({
						title: '提示',
						message: '请输入姓名',
						type: 'warning'
					});
					return false
				}
				var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
				if (!myreg.test(this.listObj.contact)) {
					this.$notify({
						title: '提示',
						message: '请输入手机号',
						type: 'warning'
					});
					return false;
				}
				var wx = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
				if (!wx.test(this.listObj.wechat_number)) {
					this.$notify({
						title: '提示',
						message: '微信号不能使用中文,必须以字母开头而且长度要达到6位',
						type: 'warning'
					});
					return false;
				}


				if (!this.listObj.works) {
					this.$notify({
						title: '提示',
						message: '请输入作品集',
						type: 'warning'
					});
					return false
				}

				if (this.listObj.works_image.length <= 0) {
					this.$notify({
						title: '提示',
						message: '请上传作品图片',
						type: 'warning'
					});
					return false
				}
				if (!this.listObj.order_time) {
					this.$notify({
						title: '提示',
						message: '请选择时间',
						type: 'warning'
					});
					return false
				}
				console.log(this.listObj)
				this.$req({
					method: 'post',
					url: '/api/teacher/course',
					data: {
						teacher_id: this.id,
						...this.listObj
					},
					success: res => {
						this.centerDialogVisible = false
						this.$notify({
							title: '成功',
							message: '提交成功',
							type: 'success'
						});
						this.listObj.order_time = ''
					},
					fail: error => {
						// if (error.data.code == 422) {
						this.$message({
							message: error.data.message,
							type: 'warning'
						});
						// }
						this.listObj.order_time = ''
						this.centerDialogVisible = false
					}
				});


			},
			followRequest() {
				this.$req({
					method: 'post',
					url: '/api/mapleCollection',
					data: {
						id: this.id,
						type: 'teacher_collect'
					},
					success: res => {
						if (this.isfollow) {
							this.$message.success('已关注')
						} else {
							this.$message('取消关注')
						}
					},
					fail: error => {
						this.$message(error.message)
						this.isfollow = !this.isfollow
					}
				});
			},
			// 关注
			follow() {

				if (this.isfollow) {
					this.isfollow = !this.isfollow;
					this.followRequest()
				} else {
					this.isfollow = !this.isfollow;
					this.followRequest()
				}
			},
			// 跳转服务项目
			toServce(item) {
				let data = JSON.stringify(item)
				this.$router.push({
					path: '/ServiceItem',
					query: {
						data: data,
						type: 1
					}
				})
			},
			// 跳转院校列表
			toSchoolList(item) {
				let data = JSON.stringify(item)
				this.$router.push({
					path: '/SchoolList',
					query: {
						data: data
					}
				})
			}
		}
	}
</script>

<style lang="less">
	.icon-position {
	    position: absolute;
	    top: 2%;
	    right: 2%;
	    z-index: 999;
	    display: block;
	}
	#mask {
	    position: fixed;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    z-index: 9999;
	    background-color: rgba(0, 0, 0, .7);
	
	    .swiper {
	        position: absolute;
	        top: 50%;
	        left: 50%;
	        transform: translate(-50%, -50%);
	        width: 1280px;
	        height: 600px;
	        overflow: hidden;
	        border-radius: 10px;
			background-color: #000000;
	    }
	}
	.swiper-indexs {
		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;
		
			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}
	
		
		.swiper-button-next,
		.swiper-button-prev {
			position: absolute;
			top: 50%;
			width: calc(var(--swiper-navigation-size)/ 44 * 27);
			height: var(--swiper-navigation-size);
			margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
			z-index: 10;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--swiper-navigation-color, var(--swiper-theme-color));
		}
		
		.swiper-button-prev {
			width: 30px;
			height: 30px;
			background: url(../../assets/img/prev.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			left: 10px;
			right: auto;
		}
		
		.swiper-button-next {
			width: 30px;
			height: 30px;
			background: url(../../assets/img/next.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			right: 10px;
			left: auto;
		}
	
		
		
		.swiper-button-prev::after {
			content: '';
		}
		
		.swiper-button-next::after {
			content: '';
		}
		
	}

	.el-date-editor.el-input,
	.el-date-editor.el-input__inner {
		width: 365px;
	}

	.form {
		border: 1px solid #e7e7e7;


	}

	.avatar-uploader {
		display: flex;
		flex-wrap: wrap;
	}

	.el-upload--picture-card {
		width: 84px;
		height: 84px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.el-upload-list--picture-card .el-upload-list__item {
		width: 84px;
		height: 84px;
	}

	.slider-box:hover .img-box {
		display: flex;
	}

	.img-box {
		background: rgba(0, 0, 0, 0.5);
		transition: all .3s;
		display: none;
	}

	.box-01-active {
		background: #FFFFFF !important;
		opacity: 1 !important;
	}

	.mySwiper {
		width: 386px;
		height: 240px;
	}

	.swiper-container {
		margin: 0;
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}



	.info {
		margin-top: -82px;

		.head-pics {
			margin-top: -30px;
		}

		.middle {
			width: 1px;
			height: 44px;
			background: #CCCCCC;
			margin: 0 15px;
		}

		.img_02 {
			width: 106px;
			height: 106px;
		}

		.info-01 {

			.follow-btn {
				padding: 0 10px;
				height: 26px;
				background: #DCFF03;
				border-radius: 17px;
			}

			.folloe_icon {
				width: 15px;
				height: 15px;
			}

			.de_04 {
				width: 32px;
				height: 32px;
			}

			.middle {
				width: 1px;
				height: 44px;
				background: #CCCCCC;
				margin: 0 15px;
			}

			.btn-01 {
				width: 137px;
				height: 34px;
				background: #DCFF03;
				border-radius: 3px;
			}

			.btn-02 {
				width: 84px;
				height: 34px;
				background: #DCFF03;
				border-radius: 3px;
			}

			.td_01 {
				width: 20px !important;
				height: 20px;
			}
		}
	}

	.list {
		padding-left: 20px;
		padding-right: 16px;
	}

	.arrow_right_blank {
		width: 14px;
		height: 14px;
	}

	.school-item {
		width: 10%;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.school-pic {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.el-overlay {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.el-dialog {
		border-radius: 8px;
	}

	.fill-popup .el-dialog__header {
		padding-top: 20px !important;
		padding-bottom: 20px !important;
	}

	.fill-popup .el-dialog__header .el-dialog__title {
		font-size: 20px;
		color: #333333;
	}

	.el-dialog--center .el-dialog__body {
		padding: 0 !important;
	}

	.input-area {
		width: 332px;
		padding-left: 16px;
		padding-right: 16px;
		height: 34px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
	}

	.name {
		width: 88px;
	}

	.name-text {
		width: 60px;
		text-align: justify;
		text-align-last: justify;
	}

	.popup-input {
		outline: none;
		border: 0;
		margin: 0;
		padding: 0;
	}

	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}

	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
		-moz-appearance: textfield;
	}

	.sure-btn {
		width: 130px;
		height: 28px;
		background: #DCFF03;
		margin: 0 auto;
		margin-top: 24px;
	}
</style>
